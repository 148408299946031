export class ContactDTO {
    id?: number;
    email?: string;
    emailPro?: string;
    fax?: string;
    siteWeb?: string;
    telMobile?: string;
    telDomicile?: string;
    telTravail?: string;

    constructor() {}
}
