import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AppConfig } from 'src/app/app.config';
import { OavConstants } from 'src/app/shared/data/OavConstants';

@Component({
  selector: 'app-external-redirection',
  templateUrl: './external-redirection.component.html',
  styleUrls: ['./external-redirection.component.css']
})
export class ExternalRedirectionComponent implements OnInit {

  constructor(private router: Router, private cookies: CookieService) { }

  ngOnInit() {
    document.getElementById('footer').style.position = 'fixed';
  }

  navToSante() {
    this.cookies.set('produit', OavConstants.PRODUIT_SANTE, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.router.navigate([OavConstants.PROFIL_SANTE_URL]);
  }

  navToACDS() {
    this.cookies.set('produit', OavConstants.PRODUIT_ACDS, undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.router.navigate([OavConstants.PROFIL_ACDS_URL]);
  }

}
