import { DevisRisqueQuestionsIdDTO } from './devisRisqueQuestionsIdDTO';
import { BesoinReponsesScoreDTO } from './besoinReponsesScoreDTO';

export class DevisRisqueQuestionsModelDTO {
    id?: DevisRisqueQuestionsIdDTO;
    response?: string;
    besoinReponse: BesoinReponsesScoreDTO;
    tarif?: number;
    constructor() {
        this.id = new DevisRisqueQuestionsIdDTO();
    }
}
