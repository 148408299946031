export abstract class RoleConstants {

  static readonly ADMIN: string = 'ROLE_ADMIN';
  static readonly USER: string = 'ROLE_USER';
  static readonly AGENT: string =  'ROLE_AGENT';
  static readonly EXT_AGENT: string = 'AGENT';
  static readonly AGENT_CLIENT: string = 'AGENT_CLIENT';
  static readonly CLIENT: string = 'CLIENT';
  static readonly PROSPECT_PARCOURS: string = 'PROSPECT_PARCOURS';
}
