import { Component, OnInit } from '@angular/core';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AppConfig } from 'src/app/app.config';
import { GuardService } from 'src/app/shared/services/guard.service';

@Component({
  selector: 'app-resume-devis',
  templateUrl: './resume-devis.component.html',
  styleUrls: ['./resume-devis.component.css']
})
export class ResumeDevisComponent implements OnInit {

  constructor(private cookies: CookieService, private router: Router, private guardService: GuardService) {}
  idDevis: any;
  isAgent: boolean;
  devisAcds: boolean;
  devisSante: boolean;
  isAbandonned: boolean;
  expired: any;

  ngOnInit() {
    this.checkForCurrentScreen();
    if (this.cookies.get('agent') && JSON.parse(this.cookies.get('agent')) != null) {
      this.isAgent = true;
    }
    const resumeDevis: any = JSON.parse( this.cookies.get('resumeDevis'));
    if (resumeDevis == null || resumeDevis === undefined) {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_PROSPECT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.ESPACE_PROSPECT]);
    } else {
      this.idDevis = resumeDevis.idDevis;
      this.expired = resumeDevis.expired;
      this.isAbandonned = resumeDevis.abandonned;
      if (resumeDevis.produit === OavConstants.PRODUIT_ACDS) {
        this.devisAcds = true;
      } else if (resumeDevis.produit === OavConstants.PRODUIT_SANTE) {
        this.devisSante = true;
      }
    }
  }

  checkForCurrentScreen() {
    this.guardService.checkCurrentScreen().subscribe(data => { });
  }

  closeResumeDevis() {
    this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_PROSPECT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.cookies.delete('resumeDevis');
    this.router.navigate([OavConstants.ESPACE_PROSPECT]);
  }


}
