import { ContactDTO } from './contactDTO';
import { AdressePostalDTO } from './adressePostalDTO';

export class SouscripteurModelDTO {
    id?: number;
    nom?: string;
    prenom?: string;
    nomNaissance?: string;
    dateNaissance?: Date;
    postier?: boolean;
    sexe?: boolean;
    mail?: string;
    civilite?: boolean;
    idPostier?: string;
    contact?: ContactDTO;
    adressePostale?: AdressePostalDTO;

    constructor() {
        this.contact = new ContactDTO();
        this.adressePostale = new AdressePostalDTO();
    }
}
