import { FormuleCotisationGarantieDTO } from './formuleCotisationGarantieDTO';
import { FormuleModelDTO } from './formuleDTO';

export class CodePromoDTO {
    id?: number;
    code?: string;
    listFormules?: Array<FormuleCotisationGarantieDTO>;
    idDevis?: number;
    idProduit?: number;
    listFormulesAcds?: Array<FormuleModelDTO>;
    constructor() {}
}
