import { PageRequest } from './pageRequest';

export class SearchParamsModel {
    constructor() {
        this.statusEncoursTransfo = this.statusDevis = this.statusPending = true;
        this.statusAbandonne = false;
        this.request = new PageRequest();
        this.nom = null;
        this.prenom = null;
        this.numDevis = null;
        this.numContrat = null;
    }
    nom ?: string;
    prenom ?: string;
    codePostale ?: string;
    email ?: string;
    dateNaissance ?: Date;
    numDevis ?: string;
    numContrat ?: string;
    statusDevis ?: Boolean;
    statusEncoursTransfo ?: Boolean;
    statusPending ?: Boolean;
    statusAbandonne ?: Boolean;
    countDetection ?: Boolean;
    request?: PageRequest;
}
