import { Injectable } from '@angular/core';
import { AppConfig } from '../../../app.config';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class BdocService {
    uri = AppConfig.securedEndpoint ;
    constructor(private http: HttpClient) { }

    getFileFromBdoc(bdoc) {
        return this.http.post(this.uri + '/bdoc/createFile', bdoc);
    }
}
