import { Component, OnInit, HostListener, AfterViewChecked } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-count-confirm-modal',
  templateUrl: './search-count-confirm-modal.component.html',
  styleUrls: ['./search-count-confirm-modal.component.css']
})
export class SearchCountConfirmModalComponent {

  title: string;
  message: string;
  options: string[];
  type: string;
  answer = '';
  public i = 0;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
      // tslint:disable-next-line: deprecation
      const keyCode = event.keyCode || event.which;
      if (keyCode === 9) {
          if (this.i >= this.options.length - 1) {
              this.i = 0;
          } else {
              this.i = this.i + 1;
          }
          document.getElementById('btn' + this.i).focus();
          return false;
      }
  }

  constructor(public bsModalRef: BsModalRef, public translate: TranslateService ) {
  }

  respond(answer: string) {
      this.answer = answer;
      this.bsModalRef.hide();
  }

  trackByFn(index, item) {
    return index;
  }


}
