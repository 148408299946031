import { Injectable } from '@angular/core';
import { OavUrlConstants } from 'src/app/shared/data/OavURLConstants';
import { AppConfig } from 'src/app/app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class VilleService {

  publicUri = AppConfig.publicEndpoint + OavUrlConstants.VILLE_ENDPOINT;

  constructor(private http: HttpClient, private cookie: CookieService) { }


  getAll() {
    return this.http.get(this.publicUri  + OavUrlConstants.LS);
  }

  getByCodePostal(code) {
    return this.http.get(this.publicUri + '/' + code);
  }
  getByCodePostalNaissance(code) {
    return this.http.get(this.publicUri + OavUrlConstants.CP_NAISSANCE + '/' + code);
  }
}
