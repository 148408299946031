enum DIRECTION {
    asc = 'ASC',
    desc = 'DESC'
}
export class PageRequest {
    pageNumber?: number;
    pageSize?: number;
    sortProperty?: string;
    direction?: DIRECTION;
    constructor() {
        this.direction = DIRECTION.asc;
        this.pageNumber = 1;
        this.sortProperty = 'firstName';
        this.pageSize = 5;
    }
}
