import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { OavUrlConstants } from 'src/app/shared/data/OavURLConstants';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  publicUri = AppConfig.publicEndpoint ;
  securedUri = AppConfig.securedEndpoint ;

  constructor(private http: HttpClient , private cookie: CookieService, private authService: AuthService) { }


  getContentBandeau() {
    return this.http.get(this.publicUri + OavUrlConstants.BANDEAU_MESSAGE + OavUrlConstants.GET_BANDEAU_MESSAGE);
  }

  refreshTheToken() {
    if (this.cookie.get('accessToken')) {
      const expires_in = Number(this.cookie.get('expires_in'));
      setTimeout(() => {
        this.callRefreshTokenWebService();
      }, ((expires_in * 60 * 1000) - 30));
    }
  }

  callRefreshTokenWebService() {
    if (this.cookie.get('accessToken')) {
      this.authService.refreshToken().subscribe(result => {
        this.cookie.set('accessToken', result.token);
        this.cookie.set('refreshToken', result.refreshToken);
        this.cookie.set('expires_in', result.expires_in);
        this.refreshTheToken();
      }, error => {
        // Error while refreshing token
      });
    }
  }
}
