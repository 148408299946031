import { AttestationVitale } from './attestationVitale';

export class Enfant {
    prenom?: string;
    nom?: string;
    regime?: number;
    dateNaissance?: Date;
    sexe?: boolean;
    codePostal?: string;
    ville?: string;
    paysNaissance?: string;
    noSS?: string;
    cleSec?: string;
    attestationVitale?: AttestationVitale;
    numSSFocusout?: boolean;
    cleSecFocusout?: boolean;
    cpFocusout?: boolean;
    cpEnfNotExist?: boolean;
    idBenef?: number;

    constructor() {
        this.attestationVitale = new AttestationVitale();
        this.regime = 1;
        this.paysNaissance = 'FR';
        this.cleSecFocusout = false;
        this.numSSFocusout = false;
        this.cpFocusout = false;
        this.cpEnfNotExist = false;
    }
}
