import { AdressePostalDTO } from './adressePostalDTO';

export class Assureur {
    id?: number;
    resilier?: boolean;
    mutuelle?: string;
    dateEcheance?: Date;
    adressePostale?: AdressePostalDTO;
    contrat?: string;
    idDevis?: string;
    constructor() {
        this.adressePostale = new AdressePostalDTO();
    }
}
