import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../../app.config';
import { OavUrlConstants } from '../../../shared/data/OavURLConstants';

@Injectable({
  providedIn: 'root'
})
export class RegimeService {
  publicUri = AppConfig.publicEndpoint + OavUrlConstants.REGIME_ENDPOINT;

  constructor(private http: HttpClient) {}

  getAllRegimes() {
    return this.http.get(this.publicUri + OavUrlConstants.LS);
  }
}
