import { OavConstants } from '../data/OavConstants';

export class CookiesEntriesModel {
    constructor() {
        this.entries = [
            'accessToken',
            'refreshToken',
            'expires_in',
            'agent',
            'userId',
            'client',
            'produit',
            'mlg',
            'searchParams',
            'externalId',
            'source',
            'user',
            'devisId',
            'filter',
            'email',
            '_latest',
            'previous',
            '_finishProcess',
            'resumeDevis',
            'scrolly',
            '_ext',
            'resumeContrat',
            'usurpationURL',
            'devis',
            'signType',
            'parcours',
            OavConstants.USER_TO_MODIF,
            OavConstants.IS_CONNECTED
        ];
    }
    entries: Array<string>;
}
