import { Adherent } from './adherent';

export class ListFormuleDTO {
    adherent?: Adherent;
    listIdFormule?: Array<string>;
    idDevis?: number;
    dateSejourFrom?: string;
    dateSejourTo?: string;

    constructor() {
        this.adherent = new Adherent();
    }
}
