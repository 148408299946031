import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from 'src/app/app.config';
import { OavUrlConstants } from '../data/OavURLConstants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentStatiqueService {

  constructor(private http: HttpClient) { }

  publicUri = AppConfig.publicEndpoint + OavUrlConstants.DOCUMENT_STATIQUE;

  getListDocumentsByProduitAndParcoursAndEcran(produit: String, parcours: String, ecran: String): Observable<any> {
    return this.http.get(this.publicUri + OavUrlConstants.GET_DOC_STATIQUE_BY_PRODUIT_AND_PARCOURS_AND_ECRAN +
      '?produit=' + produit + '&parcours=' + parcours + '&ecran=' + ecran);
  }


  getDocumentStatique(document: number): Observable<any> {
    return this.http.get(this.publicUri + OavUrlConstants.GET_DOC_STATIQUE_BY_ID + '?idDocument=' + document);
  }
}
