export class ClientDataDTO {
  idExterne: string;
  civilite: string;
  nom: string;
  prenom: string;
  nomDeNaissance: string;
  dateDeNaissance: string;
  situationFamiliale: string;
  adresse: string;
  codePostale: string;
  ville: string;
  pays: string;
  telephoneFixe: string;
  telephonePortable: string;
  email: string;
  numSS: string;
  codeRegime: string;
  iban: string;
  bic: string;
  titulaireCompte: string;
  telephone: string;
  clientUid: string;
  constructor() {}
}
