export class Conjoint {
    dateNaissance?: Date;
    nomJeuneFille?: string;
    sexe?: boolean;
    noSS?: string;
    cleSec?: string;
    nom?: string;
    prenom?: string;
    regime?: number;
    nssNull?: boolean;
    codePostal?: string;
    ville?: string;
    paysNaissance?: string;
    idBenef?: number;

    constructor() {
        this.paysNaissance = 'FR';
    }
}
