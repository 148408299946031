import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../../app.config';
import { QuestionsDevisModelDTO } from '../models/questionsDevisModelDTO';
import { CookieService } from 'ngx-cookie-service';
import { OavUrlConstants } from '../../../shared/data/OavURLConstants';

@Injectable({
  providedIn: 'root'
})
export class TarificateurService {

  privateUri = AppConfig.securedEndpoint + OavUrlConstants.TARIFICATEUR_ENDPOINT;

  constructor(private http: HttpClient, private cookie: CookieService) { }


  getInitialTarif(tarifRequest) {
    return this.http.post(this.privateUri + OavUrlConstants.ESTIMATION_DEVIS, tarifRequest);
  }

  updateTarif(questions: QuestionsDevisModelDTO) {
    return this.http.post(this.privateUri + OavUrlConstants.UPDATE_DEVIS, questions);
  }
}
