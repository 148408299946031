import { Injectable } from '@angular/core';
import { OavConstants } from '../data/OavConstants';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionDecryptionService {

  constructor() { }

  /**
   * Encrypt the given text using AES/ECB/Pkcs7
   * @param text plain text
   */
  encryptPassword(text: string): any {
    const key = CryptoJS.enc.Utf8.parse(OavConstants.PASSWORD_ENCRYPTION_KEY);
    const encryptedPassword = CryptoJS.AES.encrypt(text, key, {
      keySize: 16,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
    return encryptedPassword;
  }

  /**
   * Decrypt the given encrypted text using AES/ECB/Pkcs7
   * @param text the encrypted text
   */
  decryptPassword(text: string): any {
    const key = CryptoJS.enc.Utf8.parse(OavConstants.PASSWORD_ENCRYPTION_KEY).toString(CryptoJS.enc.Base64);
    const test = CryptoJS.AES.decrypt(text, key, {
      keySize: 16,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return test.toString(CryptoJS.enc.Utf8);
  }
}
