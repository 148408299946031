import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';
import { OavUrlConstants } from '../../shared/data/OavURLConstants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserDTO } from '../models/userDTO';
import { ReinitPasswordObj } from '../models/reinitPasswordObj';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  publicUri = AppConfig.securedEndpoint + OavUrlConstants.PROFIL_ENDPOINT + OavUrlConstants.USER;
  securedUri = AppConfig.securedEndpoint;
  entryPublicUri = AppConfig.publicEndpoint + OavUrlConstants.ENTRY_POINT_ENDPOINT + OavUrlConstants.USER;

  constructor(private http: HttpClient) { }

  getUserById(id) {
    return this.http.get(this.publicUri + '/' + id);
  }

  updateUser(user) {
    return this.http.post(this.publicUri + OavUrlConstants.UPDATE, user);
  }

  updateUserAndPwd(reinitPasswordObj: ReinitPasswordObj) {
    return this.http.post(this.entryPublicUri + OavUrlConstants.UPDATE_CREDENTIALS, reinitPasswordObj);
  }

  getUserByUsername(username) {
    return this.http.get(this.publicUri + OavUrlConstants.BY_USERNAME + '/' + username);
  }

  findUserByUsername(username) {
    return this.http.get(AppConfig.publicEndpoint + OavUrlConstants.ENTRY_POINT_ENDPOINT +
      OavUrlConstants.FIND_BY_USERNAME + '/' + username);
  }

  checkIfUserFound(username) {
    const options = { headers: new HttpHeaders() };
    return this.http.get(this.entryPublicUri + OavUrlConstants.BY_USERNAME + '/' + username, options);
  }
  getUserByIdExterne(idExterne) {
    return this.http.get(this.securedUri + OavUrlConstants.USER_ENDPOINT + OavUrlConstants.BY_ID_EXTERNE + '/' + idExterne);
  }

  getUserDetailsById(id) {
    return this.http.get(this.securedUri + OavUrlConstants.USER_ENDPOINT + OavUrlConstants.USER_DETAILS + '/' + id);
  }

  getConnectedUser() {
    return this.http.get(this.securedUri + OavUrlConstants.USER_ENDPOINT + '/me');
  }

  renitPass(username) {
    const user = new UserDTO();
    user.username = username;
    return this.http.post(this.entryPublicUri + OavUrlConstants.RENIT_PASS, user);
  }

  reinitialisePassword(reinitPasswordObj: ReinitPasswordObj) {
    return this.http.post(AppConfig.publicEndpoint + OavUrlConstants.ENTRY_POINT_ENDPOINT + OavUrlConstants.REINIT_CREDENTIALS,
      reinitPasswordObj);
  }

  lockAccount(id) {
    return this.http.post(this.securedUri + OavUrlConstants.USER_ENDPOINT + OavUrlConstants.LOCK_ACCOUNT + '/' + id, {});
  }
}
