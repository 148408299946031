import { Component, OnInit } from '@angular/core';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  assuranceAuto;
  assuranceHabitation;
  protectionJuridique;
  assuranceAccidentVie;
  complementaireSante;
  youtube;
  facebook;
  twitter;
  banquePostal;
  donneesPersonnel;
  mentionLegale;
  showAssuranceDommage = false;
  showAssurancePersonne = false;

  constructor(private router: Router, private cookies: CookieService) { }

  ngOnInit() {
    this.initData();
  }

  initData() {
    this.assuranceAuto = OavConstants.ASSURANCE_AUTO_LINK;
    this.assuranceHabitation = OavConstants.ASSURANCE_HABITATION_LINK;
    this.protectionJuridique = OavConstants.ASSURANCE_PROTECTION_JURIDIQUE_LINK;
    this.assuranceAccidentVie = OavConstants.ASSURANCE_ACCIDENT_VIE_LINK;
    this.complementaireSante = OavConstants.COMPLAIMENTAIRE_SANTE_LINK;
    this.youtube = OavConstants.YOUTUBE_LINK;
    this.facebook = OavConstants.FACEBOOK_LINK;
    this.twitter = OavConstants.TWITTER_LINK;
    this.banquePostal = OavConstants.BANQUE_POSTAL_LINK;
    this.donneesPersonnel = OavConstants.DONNEES_PERSONNEL_LINK;
    this.mentionLegale = OavConstants.MENTIONS_LEGALES_LINK;
  }

  showDommage() {
    this.showAssuranceDommage = !this.showAssuranceDommage;
  }

  showPersonne() {
    this.showAssurancePersonne = !this.showAssurancePersonne;
  }

  goMentionsLegales() {
    if (this.router.url === '/mentions-legales') {
      document.getElementById('mgl').scrollIntoView(true);
    } else {
      this.cookies.set('mlg', 'mentions legales', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      window.open('/#/mentions-legales', '_blank');
    }
  }

  goPersonalData() {
    if (this.router.url === '/mentions-legales') {
      document.getElementById('pdata').scrollIntoView(true);
    } else {
      this.cookies.set('mlg', 'personal data', undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      window.open('/#/mentions-legales', '_blank');
    }
  }

  goCookies() {
    window.open('/#/cookies', '_blank');
  }
}
