import { OavConstants } from 'src/app/shared/data/OavConstants';

export class AttestationVitale {
    isChange?: boolean;
    sizeError?: boolean;
    typeError?: boolean;
    attestation?: File;
    fileName?: string;

    constructor() {
        this.fileName = OavConstants.NULL_STRING;
        this.isChange = false;
        this.sizeError = false;
        this.typeError = false;
    }
}
