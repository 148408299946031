import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { OavUrlConstants } from '../data/OavURLConstants';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AgreementService {


  securedUri = AppConfig.securedEndpoint + OavUrlConstants.QMS;

  constructor(private http: HttpClient, private cookie: CookieService) { }


  getQmsDocument(): Observable<any> {
    return this.http.get(this.securedUri + OavUrlConstants.GET_QMS_DOC);
  }

  getDevisByIdqms(id: string) {
    return this.http.get(this.securedUri + OavUrlConstants.GET + OavUrlConstants.QMS + '/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }), responseType: 'arraybuffer'
    });
  }
  deleteFile(id: string) {
    return this.http.get(this.securedUri + OavUrlConstants.DELETE + OavUrlConstants.QMS + '/' + id);
  }
}
