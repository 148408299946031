import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppConfig } from '../../app.config';
import { CookieService } from 'ngx-cookie-service';
import { OavUrlConstants } from '../data/OavURLConstants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  publicUri = AppConfig.publicEndpoint + OavUrlConstants.PROFIL_ENDPOINT;
  tokenUrl = AppConfig.publicEndpoint + OavUrlConstants.TOKEN;
  authentification = AppConfig.publicEndpoint + OavUrlConstants.AUTHENTIFICATION;
  publicAuthUri = AppConfig.publicEndpoint + OavUrlConstants.PUBLIC_LOGIN;

  constructor(private router: Router, private http: HttpClient, private cookie: CookieService) { }
  headers = new HttpHeaders();

  obtainAccessToken(loginData) {
    const params = new URLSearchParams();
    params.append('username', loginData.username);
    params.append('password', loginData.password);
    params.append('grant_type', 'password');
    params.append('client_id', 'fooClientIdPassword');
    const headers =
      new HttpHeaders({
        'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
        'Authorization': 'Bearer ' +  'Basic ' + btoa('fooClientIdPassword:secret')
      });
    const options = { headers: headers };
    this.http.post(this.tokenUrl, params.toString(), options).subscribe(data => this.saveToken(data),
        err => alert('Invalid Credentials'));
  }

  saveToken(token) {
    this.cookie.set('accessToken', token.accessToken);
  }

  checkCredentials() {
    if (!this.cookie.check('accessToken')) {
      this.router.navigate(['/login']);
    }
  }

  logout() {
    this.cookie.delete('accessToken');
     this.router.navigate(['/login']);
  }

  login(loginPayload) {
    const headers =
    new HttpHeaders({
      'Accept': 'application/json'
    });
    const options = { headers: headers };
    return this.http.post(this.authentification, loginPayload, options);
  }

  createPublicToken(user) {
    const headers =
    new HttpHeaders({
      'Accept': 'application/json'
    });
    const options = { headers: headers };
    return this.http.post(this.publicAuthUri, user, options);
  }

  checkTokenValidity(token: string) {
  }

  refreshToken(): Observable<any> {
    const headers =
      new HttpHeaders({
        'Accept': 'application/json',
        'Cache-Control': 'no-cache',
        'X-Requested-With': 'XMLHttpRequest ',
        'Authorization': 'Bearer ' + this.cookie.get('refreshToken')
      });
    const options = {headers: headers};
    return this.http.get(AppConfig.securedEndpoint + OavUrlConstants.REFRESH_TOKEN_ENDPOINT + OavUrlConstants.GENERATE_REFRESH_TOKEN,
      options);
  }
}
