import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { OavUrlConstants } from '../data/OavURLConstants';

@Injectable({
  providedIn: 'root'
})
export class CallBackService {

  publicUri = AppConfig.publicEndpoint + OavUrlConstants.CALL_BACK_ENDPOINT;

  constructor(private http: HttpClient) { }

  createCallBack(callback) {
    return this.http.post(this.publicUri + OavUrlConstants.CR_CALLBACK, callback);
  }
}
