import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { OavUrlConstants } from 'src/app/shared/data/OavURLConstants';
import { SearchParamsModel } from '../models/searchParamsModel';
import { Observable } from 'rxjs';
import { VilleService } from 'src/app/features/process-sante/services/ville.service';

@Injectable({
    providedIn: 'root'
  })


export class EspaceAgentService {


  securedUri = AppConfig.securedEndpoint ;
  headers = new HttpHeaders();

  constructor(private http: HttpClient, private cookie: CookieService, private villeService: VilleService) { }

  searchUser(searchParams: SearchParamsModel): Observable<any> {
    const url = this.securedUri + OavUrlConstants.USER_ENDPOINT + OavUrlConstants.GET_USER_BY_PARAMS ;
    return this.http.post(url, searchParams);
  }

  getAgentByIdUser(idUser) {
    const url = this.securedUri + OavUrlConstants.AGENT_ENDPOINT + OavUrlConstants.GET_AGENT_BY_USER + '?idUser=' + idUser;
    return this.http.get(url);
  }

  getAgentByIdRH(idRH) {
    const url = this.securedUri + OavUrlConstants.AGENT_ENDPOINT + OavUrlConstants.GET_BY_ID_EXTERNE + '?idRHAgent=' + idRH;
    return this.http.get(url);
  }

}
