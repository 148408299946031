import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-resume-contrat',
  templateUrl: './resume-contrat.component.html',
  styleUrls: ['./resume-contrat.component.css']
})
export class ResumeContratComponent implements OnInit {

  constructor(private cookies: CookieService, private router: Router) { }

  idDevis: any;
  isAgent = false;
  contratAcds: boolean;
  contratSante: boolean;

  ngOnInit() {
    if (this.cookies.get('agent') && JSON.parse(this.cookies.get('agent')) != null) {
      this.isAgent = true;
    }
    const resumeContrat: any = JSON.parse( this.cookies.get('resumeContrat'));
    if (resumeContrat == null || resumeContrat === undefined) {
      this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_PROSPECT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
      this.router.navigate([OavConstants.ESPACE_PROSPECT]);
    } else {
      this.idDevis = resumeContrat.idDevis;
        this.contratAcds = resumeContrat.produit === OavConstants.PRODUIT_ACDS;
        this.contratSante = resumeContrat.produit === OavConstants.PRODUIT_SANTE;
    }
  }

  closeResumeContrat() {
     this.cookies.delete('resumeContrat');
     this.cookies.set(OavConstants.CURRENT_SCREEN, OavConstants.ECRAN_ESPACE_PROSPECT
        , undefined, undefined, undefined, AppConfig.cookieSecure, 'Lax');
    this.router.navigate([OavConstants.ESPACE_PROSPECT]);
  }

}
