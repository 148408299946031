import { Country } from './country';
import { City } from './city';

export class Address {
    addressSupplement?: string;
    city?: City;
    country?: Country;
    place?: string;
    residenceBuilding?: string;
    streetNumber?: string;
    constructor() {
        this.city = new City();
        this.country = new Country();
    }
}
