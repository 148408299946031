import { Directive, ElementRef, Input, SimpleChanges, OnChanges } from '@angular/core';

@Directive({
    selector: '[placeholderDetector]',
    exportAs: 'oavElement'
})
export class OavPlaceholderDetector implements OnChanges {
    constructor(private hostElement: ElementRef) { }

    @Input() model: any;

    ngOnChanges(changes: SimpleChanges) {
        this.hostElement.nativeElement.oav_dirty = false;
        if (changes.model) {
            const current = JSON.stringify(changes['model'].currentValue);
            const previous = JSON.stringify(changes['model'].previousValue);
            if (current !== 'undefined' && current !== 'null' && previous !== 'undefined' &&
            previous !== 'null' && current !== undefined && previous !== undefined) {
                this.hostElement.nativeElement.oav_dirty = true;
            }
        }
    }
}
