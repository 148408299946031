export class AdressePostalDTO {
    id?: number;
    adresseLigne1?: string;
    adresseLigne2?: string;
    adresseLigne3?: string;
    codePostale?: string;
    ville?: string;
    adresseFormatee?: string;
    pays?: string;
    complement?: string;
    lieuDit?: string;

    constructor() {}
}
