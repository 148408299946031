import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FooterComponent } from './components/footer/footer.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DateAdapter, MatNativeDateModule, MatDatepickerModule } from '@angular/material';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DateFormat } from './date-format';
import { ToastrModule } from 'ngx-toastr';
import { RegimeService } from './features/process-sante/services/regime.service';
import { CookieService } from 'ngx-cookie-service';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ProcessSanteModule } from './features/process-sante/process-sante.module';
import { CallBackComponent } from './shared/components/callBack/callBack.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CookiesBannerComponent } from './components/cookies-banner/cookies-banner.component';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { MentionsLegalesComponent } from './components/mentions-legales/mentions-legales.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { TimeoutComponent } from './components/navbar/timeout/timeout.component';
import { HttpConfigInterceptor } from './shared/interceptor/httpconfig-interceptor';
import { CookiesComponent } from './components/cookies/cookies.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '../../assets/i18n/');
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    CallBackComponent,
    CookiesBannerComponent,
    MentionsLegalesComponent,
    TimeoutComponent,
    CookiesComponent
  ],
  imports: [
    BrowserModule,
    ProcessSanteModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    NgxCaptchaModule,
    BsDatepickerModule.forRoot(),
    MatDatepickerModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    }),
    PaginationModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [{ provide: DateAdapter, useClass: DateFormat },
              { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
              RegimeService, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
