export class ExternalClient {

    idExterne?: string;
        nom?: string;
        prenom?: string;
        nomDeNaissance?: string;
        dateDeNaissance?: string;
        situationFamiliale?: string;
        adresse?: string;
        codePostale?: string;
        ville?: string;
        pays?: string;
        telephoneFixe?: string;
        telephonePortable?: string;
        email?: string;
        numSS?: string;
        codeRegime?: string;
        iban?: string;
        bic?: string;
        titulaireCompte?: string;
        civilite?: string;
    constructor() {
        this.idExterne = null;
        this.nom = null;
        this.prenom = null;
        this.nomDeNaissance = null;
        this.dateDeNaissance = null;
        this.situationFamiliale = null;
        this.adresse = null;
        this.codePostale = null;
        this.ville = null;
        this.pays = null;
        this.telephoneFixe = null;
        this.telephonePortable = null;
        this.email = null;
        this.numSS = null;
        this.codeRegime = null;
        this.iban = null;
        this.bic = null;
        this.titulaireCompte = null;
        this.civilite = null;
    }
}
