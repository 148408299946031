import { BesoinReponsesScoreDTO } from './besoinReponsesScoreDTO';

export class BesoinQuestionsDTO {
    idBesoinQuestions?: number;
    // private Long idProduit;
    // private String typeParcours;
    // private Integer ordre;
    // private String libelleLongQuestion;
    // private String libelleCourtQuestion;
    // private String infobulleQuestion;
    // private String typeResponse;
    // private Long parentId;
    besoinReponsesScores: Array<BesoinReponsesScoreDTO>;

    constructor() {
        this.besoinReponsesScores = new Array<BesoinReponsesScoreDTO>();
    }
}
