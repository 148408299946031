import { Adherent } from './adherent';
import { SouscripteurModelDTO } from './souscripteurModelDTO';

export class SignatureModelDTO {
    email?: string;
    nom?: string;
    prenom?: string;
    freqPrelevement?: string;
    iban?: string;
    codeBic?: string;
    modeenvoie?: string;
    respTns?: string;
    dateClotureComptable?: Date;
    currentAdherent?: SouscripteurModelDTO;
    tel?: string;
    idFormuleRec?: number;
    modePaiment?: string;
    idDevis?: number;
    titulaire?: string;
    sendToUser?: Boolean;

    constructor() {
        this.currentAdherent = new SouscripteurModelDTO();
    }
}
