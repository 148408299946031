import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../../app.config';
import { SignatureModelDTO } from '../models/signatureDTO';
import { OavUrlConstants } from '../../../shared/data/OavURLConstants';

@Injectable({
  providedIn: 'root'
})
export class SignatureService {

  privateUri = AppConfig.securedEndpoint + OavUrlConstants.SIGNATURE_ENDPOINT;

  constructor(private http: HttpClient) { }


  sign(signatureRequest: SignatureModelDTO, bdocDto) {
    const signDTO = {
      bdocRequest : bdocDto,
      signatureModelDTO : signatureRequest
    };
    return this.http.post(this.privateUri + OavUrlConstants.CR, signDTO);
  }

  sendMailFinalisation(signatureRequest: SignatureModelDTO, bdocDto) {
    const signDTO = {
      bdocRequest : bdocDto,
      signatureModelDTO : signatureRequest
    };
    return this.http.post(this.privateUri + OavUrlConstants.MAIL_FINALISATION_DEVIS, signDTO);
  }

  finishSign(idDevis) {
    return this.http.post(this.privateUri + OavUrlConstants.FINISH_SIGN + '/' + idDevis, {});
  }
  getDocs(idDevis, parcours) {
    return this.http.post(this.privateUri + OavUrlConstants.GET + OavUrlConstants.TESSI_DOCS + '/' + idDevis + '/' + parcours, {});
  }
  getPdf(idDoc) {
    return this.http.post(this.privateUri + OavUrlConstants.DOWNLOAD_PDF_TESSISIGN + '/' + idDoc, {}, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }), responseType: 'arraybuffer'
    });
  }

  updatePackageTessiSignStatus(devisId: any, status: any) {
    return this.http.put(this.privateUri + OavUrlConstants.UPDATE_PACKAGE_TESSI_SIGN_STATUS + '/' + devisId + '/' + status, {});
  }

}

