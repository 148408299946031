import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OavUrlConstants } from 'src/app/shared/data/OavURLConstants';
import { AppConfig } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  securedUri = AppConfig.securedEndpoint;

  constructor(private http: HttpClient) { }

  filterUsers(userList) {
    return this.http.post(this.securedUri + OavUrlConstants.FILTER_ENDPOINT + OavUrlConstants.FILTER_USERS, userList);
  }
}
