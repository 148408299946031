export class CallBakcDTO {
    idDevis?: number;
    dateRappel?: Date;
    nom?: string;
    prenom?: string;
    civilite?: string;
    datePref?: string;
    telephone?: string;
    produit?: string;
    isRdv?: Boolean;
    constructor() {
    }
}
