import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Idle } from 'idlejs';
import { CookieService } from 'ngx-cookie-service';
import { OavConstants } from 'src/app/shared/data/OavConstants';
import { CookiesEntriesModel } from 'src/app/shared/models/cookiesEntries.Model';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.css']
})
export class TimeoutComponent implements OnInit, OnChanges {

  constructor(private cookies: CookieService, private router: Router) { }
  isSessionCloseToExpire = false;
  isSessionExpired = false;
  startIdle = true;
  downCounter = '';
  idle: Idle;
  timerTimeout: any;
  remainingSecondsToInactive: number;
  idleCounter: any;
  idleStatus: number = OavConstants.IDLE_STATUS_USER_ACTIVE;
  ngOnInit() {

  }
  @Input('userActivityEvent') userActivityEvent: any;

  ngOnChanges(changes: SimpleChanges) {
    if (this.idleStatus === OavConstants.IDLE_STATUS_USER_AWAY) {
      this.recoveryAfterIdle();
    }
    this.resetRemainingSecondsToInactive();
  }


  resetRemainingSecondsToInactive() {
    this.remainingSecondsToInactive = OavConstants.IDLE_TOTAL_DURATION_ALLOWED_TIME_IN_SECONDS;
    this.idleStatus = OavConstants.IDLE_STATUS_USER_ACTIVE;
    clearInterval(this.idleCounter);

    this.idleCounter = setInterval(() => {
      this.downCounter = this.calculateCountDown(this.remainingSecondsToInactive);
      if (!OavConstants.LOGIN_URL.includes(this.router.url) && ((!this.isUserLoggedIn() &&
        !OavConstants.PROFIL_URL_LIST.includes(this.router.url)) || (this.isUserLoggedIn()))) {
        this.triggerIdleStatus();
      }

    }, 1000);
  }



  triggerIdleStatus() {
    if (this.remainingSecondsToInactive <= OavConstants.IDLE_DURATION_UNTIL_WARNING_IN_SECONDS) {
      this.idleStatus = OavConstants.IDLE_STATUS_IDLE_WARNING;
    }
    this.remainingSecondsToInactive--;
    if (this.remainingSecondsToInactive < 0) {
      this.idleStatus = OavConstants.IDLE_STATUS_USER_AWAY;
      clearInterval(this.idleCounter);
    }
  }

  recoveryAfterIdle() {
    // Not Logged In
    if (!OavConstants.LOGIN_URL.includes(this.router.url)) {
      if ((this.isUserLoggedIn()) || (!this.isUserLoggedIn() && !OavConstants.FIRST_STEPS_URL_LIST.includes(this.router.url))) {
        this.logout();
      } else if (!this.isUserLoggedIn() && (!OavConstants.PROFIL_URL_LIST.includes(this.router.url))) {
        this.navigateToProfil();
      }

    }
  }
  isUserLoggedIn() {
    const agent = this.cookies.get('agent');
    const client = this.cookies.get('client');
    const isAgent = (agent && JSON.parse(agent) && JSON.parse(agent).agentUid);
    const isClient = (client && JSON.parse(client) && JSON.parse(client).clientUid);
    return (isAgent || isClient);
  }

  logout() {
    const cookiesEntries = new CookiesEntriesModel().entries;
    cookiesEntries.forEach(entry => {
      this.cookies.delete(entry);
    });
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate([OavConstants.LOGIN_URL]);
  }

  navigateToProfil() {
    const cookiesEntries = new CookiesEntriesModel().entries;
    cookiesEntries.forEach(entry => {
      this.cookies.delete(entry);
    });
    if (this.router.url.startsWith(OavConstants.PROFIL_SANTE_URL)) {
      this.router.navigate([OavConstants.PROFIL_SANTE_URL]);
    } else {
      this.router.navigate([OavConstants.PROFIL_ACDS_URL]);
    }
  }

  /**
 * Calculate timer for hours, minutes and seconds
 * @param time the time in seconds
 */
  calculateCountDown(time: number): string {
    const hours = Math.floor(time / (60 * 60));
    const minutes = Math.floor((time % (60 * 60)) / 60);
    const seconds = Math.floor(time % 60);
    return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
  }

}
