import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OavDateService {

  constructor() { }

  public saveUTCDate(value: Date): Date {
    return new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(),
      value.getHours(), value.getMinutes(), value.getSeconds(), value.getMilliseconds()));
  }

  public loadUTCDate(value): Date {
    const incomingDate = new Date(value.substring(0, 4), parseInt(value.substring(5, 7), 10) - 1,
      value.substring(8, 10), value.substring(11, 13), value.substring(14, 16), value.substring(17, 19));
    const targetDate = incomingDate;
    targetDate.setMilliseconds(incomingDate.getUTCMilliseconds());
    targetDate.setSeconds(incomingDate.getUTCSeconds());
    targetDate.setMinutes(incomingDate.getUTCMinutes());
    targetDate.setHours(incomingDate.getUTCHours());
    targetDate.setDate(incomingDate.getUTCDate());
    targetDate.setMonth(incomingDate.getUTCMonth());
    targetDate.setFullYear(incomingDate.getUTCFullYear());
    return targetDate;
  }


}
