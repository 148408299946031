import { Person } from './person';

export class FilterRequestList {
    partner?: string;
    person?: Array<Person>;
    unit?: string;
    userId?: string;
    constructor() {
        this.partner = 'TESSI';
        this.unit = 'SANTE';
        this.userId = 'weboav'; // XXX132456
        this.person = new Array<Person>();
    }
}
