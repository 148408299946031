export enum DevisStatus {
    EN_COURS = 'En cours',
    DEVIS = 'Devis',
    INFORMATION = 'Information',
    MANDAT = 'Mandat',
    // PJ_EN_COURS = 'Piéce jointes en cours',
    A_SIGNER = 'A signer',
    // signature effectué
    // A_PYER = 'A payer',
    // envoyé à la gestion
    A_ENVOYER_EN_GESTION = 'A envoyer en gestion',
    // Adhésion abandonnée par l'utilisateur
    ABANDONNE = 'Abandonné',
    // Devis dont la date de validité est expirée
    // OBSOLETE = 'Obsolete'
    EN_ATTENTE_SIGNATURE = 'En attente signature',
    PENDING = 'Pending',
    REJCTED = 'Rejeté',
    A_PAYER = 'A payer'
}
