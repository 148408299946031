import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { OavUrlConstants } from 'src/app/shared/data/OavURLConstants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaysService {

  privateUri = AppConfig.securedEndpoint + OavUrlConstants.PAYS_ENDPOINT;

  constructor(private http: HttpClient) { }

  getall() {
    return this.http.get(this.privateUri + OavUrlConstants.LS);
  }
}
