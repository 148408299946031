import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class SanticlaireService {

  publicUri = AppConfig.securedEndpoint + '/santeclair/authentication';

  constructor(private http: HttpClient) { }

  getGeoclaireUrl() {
    return this.http.post(this.publicUri, {});
  }
}
