import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../../app.config';
import { OavUrlConstants } from '../../../shared/data/OavURLConstants';
import { DevisModelDTO } from '../models/devisDTO';
import { ContratDTO } from '../models/contratDTO';
import { Observable } from 'rxjs';
import { CodePromoDTO } from '../models/codePromoDTO';

@Injectable({
  providedIn: 'root'
})
export class FormulesService {
  privateUri = AppConfig.securedEndpoint + OavUrlConstants.FORMULE_ENDPOINT;

  constructor(private http: HttpClient) { }

  getFormuleByTarif(tarifDTO: DevisModelDTO) {
    return this.http.post(this.privateUri + OavUrlConstants.LS, tarifDTO);
  }

  getDetailOffre(id) {
    return this.http.post(this.privateUri + OavUrlConstants.DOWNLOAD_PDF, id);
  }
  getListCodes(idDevis, idProduit) {
    return this.http.get(this.privateUri + OavUrlConstants.LS_CODE_PROMO + '?idDevis=' + idDevis + '&idProduit=' + idProduit);
  }
  createCallBack(call) {
    return this.http.post(this.privateUri + OavUrlConstants.CR_CALLBACK, call);
  }
  createCourrier(courrier) {
    return this.http.post(this.privateUri + OavUrlConstants.CR_COURRIER, courrier);
  }
  getFormuleACDS(tarifDTO: DevisModelDTO) {
    return this.http.post(this.privateUri + OavUrlConstants.LS_FORMULES_ACDS, tarifDTO);
  }
  searchContrat(model: ContratDTO) {
    return this.http.post(this.privateUri + OavUrlConstants.SEARCH_CONTRAT_CONJOINT, model);
  }
  getPdf(devis: DevisModelDTO): Observable<any> {
    return this.http.post(this.privateUri + OavUrlConstants.DOWNLOAD_PDF, devis, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }), responseType: 'arraybuffer'
    });
  }
  getCG(devis: DevisModelDTO): Observable<any> {
    return this.http.post(this.privateUri + OavUrlConstants.DOWNLOAD_PDF_CG, devis, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }), responseType: 'arraybuffer'
    });
  }
  verifValidCode(model: CodePromoDTO) {
    return this.http.post(this.privateUri + OavUrlConstants.VALIDATE_PROMO, model);
  }
}
