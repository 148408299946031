import { DevisModelDTO } from './devisDTO';
import { BesoinQuestionsDTO } from './besoinQuestionDTO';

export class DevisRisqueQuestionsIdDTO {
    devis: DevisModelDTO;
    besoinQuestions: BesoinQuestionsDTO;

    constructor() {
        this.devis = new DevisModelDTO();
        this.besoinQuestions = new BesoinQuestionsDTO();
    }
}
