export class ReferenceBancaire {
    id?: string;
    nom?: string;
    prenom?: string;
    adresseLigne1?: string;
    codePostale?: string;
    ville?: string;
    iban?: string;
    bic?: string;
    idDevis?: number;
    titulaire?: string;
    titulairePrest?: string;
    ibanPrest?: string;
    bicPrest?: string;
    jourPrelevement?: string;
    modeEnvoi?: string;
    typeSignature?: string;
    statusDevis?: string;
    modePaiement?: string;
    premiereCotisation?: string;
    constructor() {}
}
