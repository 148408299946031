import { LieuNaissance } from './lieuNaissance';
import { Assureur } from './assureur';
import { AdressePostalDTO } from './adressePostalDTO';
import { ContactDTO } from './contactDTO';
import { RefBancaireModelDTO } from './refBancaireDTO';

export class UserDTO {
    id?: number;
    civilite?: boolean;
    nom?: string;
    prenom?: string;
    email?: string;
    dateNaissance?: string;
    adresseLigne1?: string;
    postalCodes?: string;
    complement?: string;
    lieuDit?: string;
    ville?: string;
    idExterne?: string;
    telephone?: string;
    lieuNaissance?: LieuNaissance;
    noSS?: number;
    cleSec?: string;
    attestationVitale?: File;
    regime?: number;
    modePaiement?: string;
    assureur?: Assureur;
    refuseOffreCurrier?: boolean;
    offreVoieElectronique?: boolean;
    adressePostale?: AdressePostalDTO;
    contactUser?: ContactDTO;
    refBancaire?: RefBancaireModelDTO;
    fakeEmail?: boolean;

    constructor() {
        this.lieuNaissance = new LieuNaissance();
        this.assureur = new Assureur();
        this.adressePostale = new AdressePostalDTO();
        this.contactUser = new ContactDTO();
        this.refBancaire = new RefBancaireModelDTO();
    }
}
