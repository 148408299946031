import { AuthorityDTO } from './authorityDTO';

export class UserDTO {
    id?: number;
    username?: string;
    password?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    createdDate?: Date;
    accountExpired?: boolean;
    accountLocked?: boolean;
    credentialsExpired?: boolean;
    passwordExpired?: boolean;
    authorities?: Array<AuthorityDTO>;
    actif?: boolean;
    civilite: string;
    nomDeNaissance: string;
    dateDeNaissance: string;
    situationFamiliale: string;
    adresse: string;
    codePostale: string;
    ville: string;
    pays: string;
    telephoneFixe: string;
    telephonePortable: string;
    numSS: string;
    codeRegime: string;
    iban: string;
    bic: string;
    titulaireCompte: string;
    tentative: number;
    activationId: string;
    dateDerniereModification?: Date;
    constructor() {
        this.authorities = new Array<AuthorityDTO>();
    }
}
