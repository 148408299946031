import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { OavUrlConstants } from 'src/app/shared/data/OavURLConstants';
import { HttpClient } from '@angular/common/http';
import { DevisModelDTO } from 'src/app/features/process-sante/models/devisDTO';

@Injectable({
  providedIn: 'root'
})
export class EspaceProspectService {
  securedUri = AppConfig.securedEndpoint;

  constructor(private http: HttpClient) { }

  getBeneficiaireByIdUser(id) {
    const url = this.securedUri + OavUrlConstants.BENEFICIAIRE_ENDPOINT + OavUrlConstants.GET_BENEFICAIRE_BY_ID_USER ;
    return this.http.get( url + '/' + id);
  }
  getContratsByIdUser(idExterne: any) {
    const url = this.securedUri + OavUrlConstants.USER_ENDPOINT + OavUrlConstants.CONTRATS_BY_ID_EXTERNE ;
    return this.http.get( url + '/' + idExterne);
  }

  getDevisByIdUser(idUser: any) {
    const url = this.securedUri + OavUrlConstants.DEVIS_ENDPOINT + OavUrlConstants.GET_DEVIS_BY_ID_USER ;
    return this.http.get( url + '/' + idUser);
  }

  updateDevisStatus(idDevis, status) {
    const devis = new DevisModelDTO();
    devis.idDevis = idDevis;
    devis.status = status;
    const url = this.securedUri + OavUrlConstants.DEVIS_ENDPOINT + OavUrlConstants.UPDATE_DEVIS_STATUS ;
    return this.http.put(url, devis);
  }

}
