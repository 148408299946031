import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { OavUrlConstants } from 'src/app/shared/data/OavURLConstants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BesoinsService {

  constructor(private http: HttpClient) { }

  securedUri = AppConfig.securedEndpoint + OavUrlConstants.BESOINS_ENDPOINT;

  getQuestionsReponsesScoresByDevis(idDevis): Observable<any> {
    return this.http.get(this.securedUri + OavUrlConstants.QUESTIONS_REPONSES_SCORE + '?idDevis=' + idDevis);
  }

  saveReponse(devis): Observable<any> {
    return this.http.post(this.securedUri + OavUrlConstants.SAVE_REPONSES, devis);
  }
}
