export class PublicUser {
    username?: String;
    password?: String;
    role?: String;

    constructor(id: string, pwd: string) {
        this.username = id;
        this.password = pwd;
        this.role = 'PUBLIC_USER';
    }
}
