export class AgentDataDTO {
  idExterne: string;
  id: string;
  idRHAgent: string;
  nom: string;
  prenom: string;
  codeBureau: string;
  libelleBureau: string;
  codeReseau: string;
  libelleReseau: string;
  emailAgent: string;
  type: string;
  role: string;
  profil: string;
  groupeRattachement: string;
  bureau: string;
  constructor() {}
}
