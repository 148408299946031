export class PaymentOptions {
    nom?: string;
    prenom?: string;
    codePostale?: string;
    adresse?: string;
    ville?: string;
    iban?: string;
    bic?: string;
    modeenvoie?: string;
    titulaire?: string;
    titulairePrest?: string;
    ibanPrest?: string;
    bicPrest?: string;
    jourPrelevement?: string;
    modeEnvoi?: string;
    modePaiement?: string;
    idRefBancaire?: number;
    premiereCotisation?: string;
    constructor() {}
}
