import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PopupWrapper } from '../../models/popupWrapper';
import { Location } from '@angular/common';
import { AgreementService } from '../../services/agreement.service';
import { Input } from 'src/app/models/inputModel';
import { OavPDFAnnotationData } from 'src/app/models/oavPDFAnnotationData';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { DevisModelDTO } from 'src/app/features/process-sante/models/devisDTO';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { OavDateService } from '../../services/oav-date.service';
import { OavConstants } from '../../data/OavConstants';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.css'],
  // tslint:disable-next-line: use-host-property-decorator
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class AgreementComponent extends PopupWrapper implements OnInit, AfterViewInit {
  devisId;
  fullRead = false;
  returnedObject = false;
  checkboxValue = false;
  encodedDocument: string;
  // screen DPI / PDF DPI
  readonly dpiRatio = 96 / 72;
  formPdf: FormGroup;
  inputList: Input[] = [];
  zoom = 1;
  devis: DevisModelDTO;
  originViwerWidth: number = null;
  originInputList: any = [];
  domRender = false;
  globalZommFactor = 1;
  result = false;
  pdfDisplaySideOffset = 10;
  originFontSize = 14;
  currentZoomDisplay = 1;
  loadingSpinner = true;
  showMsg = false;
  checkedValues = [];

  constructor(public bsModalRef: BsModalRef, private location: Location, private agreementService: AgreementService,
    private oavDateService: OavDateService, private spinner: NgxSpinnerService, private formBuilder: FormBuilder,
    private cookies: CookieService) {
    super();
    this.formPdf = this.formBuilder.group({});
  }

  onResize(event) {
    setTimeout(() => {
      this.loadingSpinner = true;
      this.spinner.show();
      this.autoAdjustZoomFactor();
    });
  }


  ngAfterViewInit() {
    this.spinner.show();
  }

  ngOnInit() {
    this.agreementService.getQmsDocument().subscribe(rep => {
      this.encodedDocument = URL.createObjectURL(this.base64ToBlob(rep.encoded));
    });
  }

  base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: 'application/pdf' });
  }

  changeEvent(event) {
    this.checkboxValue = event;
  }

  backViewTab() {
    this.returnedObject = false;
    this.bsModalRef.hide();
    this.location.back();
  }

  accept() {
    this.bsModalRef.hide();
    this.returnedObject = true;
  }



  /*****************************[Editable PDF]***************************** */

  createInput(annotation: OavPDFAnnotationData, rect: number[] = null) {
    let formControl = new FormControl(annotation.buttonValue || '');
    const input = new Input();
    input.name = annotation.fieldName;
    if (annotation.fieldType === 'Tx') {
      input.type = 'text';
      input.fontSize = this.originFontSize - 3;
      if (input.name === 'nom_souscripteur') {
        input.value = this.capitalize(this.devis.souscripteur.nom) + ' ' + this.capitalize(this.devis.souscripteur.prenom);
      } else if (input.name === 'nom_assure') {
        input.value = this.capitalize(this.devis.beneficiaires[0].nomBenef) + ' ' +
          this.capitalize(this.devis.beneficiaires[0].prenomBenef);
      } else if (input.name === 'date_naissance') {
        input.value = moment(this.oavDateService.loadUTCDate(this.devis.beneficiaires[0].dateNaissance)).format('DD/MM/YYYY');
      } else {
        input.value = annotation.buttonValue || '';
      }
    }

    if (annotation.fieldType === 'Btn' && !annotation.checkBox) {
      input.type = 'radio';
      input.value = annotation.buttonValue;
    }

    if (annotation.checkBox) {
      input.type = 'checkbox';
      input.value = true;
      formControl = new FormControl(annotation.buttonValue || false);
    }

    // Calculate all the positions and sizes
    if (rect) {
      input.top = rect[1] - (rect[1] - rect[3]);
      input.left = rect[0];
      input.height = (rect[1] - rect[3]);
      if (annotation.fieldType === 'Tx') {
        input.width = (rect[2] - rect[0]) + (rect[2] - rect[0]) * 1.55;
      } else {
        input.width = (rect[2] - rect[0]);
      }

    }

    this.inputList.push(input);
    this.originInputList.push(Object.assign({}, input));
    return formControl;
  }

  addInput(annotation: OavPDFAnnotationData, rect: number[] = null): void {
    // add input to page
    this.formPdf.addControl(annotation.fieldName, this.createInput(annotation, rect));
    this.formPdf.get(annotation.fieldName).valueChanges.subscribe(event => {
      setTimeout(() => {
        let refuse = false;
        let confirmAll = 0;

        /** Checkbox with radio button behaviour */

        if (event === true) {
          let objectKey = annotation.fieldName.substr(0, annotation.fieldName.indexOf('_'));
          if (annotation.fieldName.includes('_non')) {
            objectKey += '_oui';
          }
          if (annotation.fieldName.includes('_oui')) {
            objectKey += '_non';
          }
          this.formPdf.get(objectKey).setValue(false);
        }


        const total_q_non = Object.keys(this.formPdf.value).filter(key => key.toString().includes('_non')).length;
        Object.keys(this.formPdf.value).forEach(key => {
          if (key.toString().includes('_oui') && this.formPdf.value[key] === true) {
            refuse = true;
          }
          if (key.toString().includes('_non') && this.formPdf.value[key] === true) {
            confirmAll++;
          }
        });
        this.result = confirmAll === total_q_non && !refuse;
      });
    });
  }

  getInputPosition(input: Input, label: boolean = false): any {
    let inputPosition: any;
    if (input.type === 'text') {
      inputPosition = {
        top: `${input.top + (input.height / 7)}px`,
        left: `${input.left}px`,
        height: `${input.height}px`,
        width: `${input.width}px`,
        fontSize: `${input.fontSize}px`
      };
    } else {
      inputPosition = {
        top: `${input.top}px`,
        left: `${input.left}px`,
        height: `${input.height}px`,
        width: `${input.width}px`,
        fontSize: `${input.fontSize}px`
      };
    }

    if (label) {
      const fontSize = this.originFontSize * this.globalZommFactor;
      return {
        top: `${input.top - (input.height / 4)}px`,
        left: `${input.left + input.width + this.globalZommFactor}px`,
        fontSize: `${fontSize}px`
      };
    }
    return inputPosition;
  }


  zoomIn(zoomFactor, callFromDom): void {
    if (callFromDom) {
      this.loadingSpinner = true;
      this.spinner.show();
      zoomFactor = this.zoom + 0.25;
    }
    this.zoom = zoomFactor;
    this.resetInputBox();
    setTimeout(() => {
      this.globalZommFactor = (document.getElementById('viewer').getBoundingClientRect().width / this.originViwerWidth);
      this.inputList = this.inputList.map(i => {
        i.left *= this.globalZommFactor;
        i.top *= this.globalZommFactor;
        i.width *= this.globalZommFactor;
        i.height *= this.globalZommFactor;
        i.fontSize *= this.globalZommFactor;
        return i;
      });
      this.loadingSpinner = false;
      this.checkLoading();
    });
  }

  zoomOut(zoomFactor, callFromDom): void {
    if (callFromDom) {
      this.loadingSpinner = true;
      this.spinner.show();

      zoomFactor = this.zoom - 0.25;
    }
    this.zoom = zoomFactor;
    this.resetInputBox();

    setTimeout(() => {
      this.globalZommFactor = (document.getElementById('viewer').getBoundingClientRect().width / this.originViwerWidth);
      this.inputList = this.inputList.map(i => {
        i.left *= this.globalZommFactor;
        i.top *= this.globalZommFactor;
        i.width *= this.globalZommFactor;
        i.height *= this.globalZommFactor;
        i.fontSize *= this.globalZommFactor;
        return i;
      });
      this.loadingSpinner = false;
      this.checkLoading();
    });
  }

  checkLoading() {
    return new Promise(resolve => {
      setTimeout(() => {
        if (!this.loadingSpinner) {
          this.spinner.hide();
          this.currentZoomDisplay = document.getElementById('viewer').getBoundingClientRect().width /
            document.getElementById('container-pdf-id').getBoundingClientRect().width;
        }
        resolve();
      });
    });
  }

  loadComplete(pdf: PDFDocumentProxy): void {
    for (let i = 1; i <= pdf.numPages; i++) {
      // track the current page
      let currentPage = null;
      pdf.getPage(i).then(p => {
        currentPage = p;
        // get the annotations of the current page
        return p.getAnnotations();
      }).then(ann => {
        const annotations = (<any>ann) as OavPDFAnnotationData[];
        annotations
          .filter(a => a.subtype === 'Widget') // get the form field annotation only
          .forEach(a => {
            // get the rectangle that represent the single field
            // and resize it according to the current DPI
            const fieldRect = currentPage.getViewport({ scale: this.dpiRatio })
              .convertToViewportRectangle(a.rect);
            // add the corresponding input
            this.addInput(a, fieldRect);
          });
        setTimeout(() => {
          this.autoAdjustZoomFactor();
        });
      });
    }
  }

  resetInputBox() {
    this.inputList.forEach((input, index) => {
      input.left = this.originInputList[index].left;
      input.top = this.originInputList[index].top;
      input.width = this.originInputList[index].width;
      input.height = this.originInputList[index].height;
      input.fontSize = this.originInputList[index].fontSize;
    });
  }

  autoAdjustZoomFactor() {
    if (this.originViwerWidth == null) {
      this.originViwerWidth = document.getElementById('viewer').getBoundingClientRect().width;
    }
    if (document.getElementById('viewer').getBoundingClientRect().width <
      document.getElementById('container-pdf-id').getBoundingClientRect().width) {
      const zoomFactor = (document.getElementById('container-pdf-id').getBoundingClientRect().width / this.originViwerWidth);
      this.zoomIn(zoomFactor, false);
      this.currentZoomDisplay = 1;
    }
    if (document.getElementById('viewer').getBoundingClientRect().width >
      document.getElementById('container-pdf-id').getBoundingClientRect().width) {
      const zoomFactor = (document.getElementById('container-pdf-id').getBoundingClientRect().width / this.originViwerWidth);
      this.zoomOut(zoomFactor, false);
      this.currentZoomDisplay = 1;
    }
  }

  capitalize(string) {
    if (string) {
      return string[0].toUpperCase() + string.slice(1);
    } else {
      return string;
    }
  }


  getZoomBtnPosition() {
    return {
      top: `${document.getElementById('viwer').getBoundingClientRect().height - 200}px`
    };
  }


  getZoom() {
    return (Math.round(Math.abs(this.currentZoomDisplay * 100)).toFixed(0));
  }

  download() {
    this.devisId =  this.cookies.get(OavConstants.DEVIS);
    this.agreementService.getDevisByIdqms(this.devisId).subscribe((data: ArrayBuffer) => {
      const newBlob = new Blob([data], { type: 'application/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const dataa = window.URL.createObjectURL(newBlob);
      const a = document.createElement('a');
      a.href = dataa;
      a.download = 'QMS.pdf';
      a.click();
      this.agreementService.deleteFile(this.devisId).subscribe((res) => {
      }, error => {
      });
    }, error => {
    });
  }

  trackByFn(index, item) {
    return index;
  }

  checkRadioChanges(name: string, value: boolean): void {
    const key = name.substring(0, name.length - 3);
    this.checkedValues[key] = this.checkedValues[key] && value ? false : value;
    this.showMsg = false;
    for (const ke in this.checkedValues) {
      if (this.checkedValues[ke]) {
        this.showMsg = true;
      }
    }
  }
}
