import { FormuleModelDTO } from './formuleDTO';
import { FamilleActeDTO } from './familleActeDTO';

export class FormuleCotisationGarantieDTO {
    formule?: FormuleModelDTO;
    familleActes?: Array<FamilleActeDTO>;

    constructor() {
        this.formule = new FormuleModelDTO();
        this.familleActes = new Array<FamilleActeDTO>();
    }
}
