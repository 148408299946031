import { FileBeneficiareDTO } from './fileBeneficiareDTO';

export class FilesDTO {
    // fileCarteID?: File;
    // fileMutuelle?: File;
    // fileRIB?: File;
    // fileSec?: File;
    // fileSecF?: File;
    // fileSecE1?: File;
    // fileSecE2?: File;
    // fileSecE3?: File;
    // fileSecE4?: File;
    // fileJustE1?: File;
    // fileJustE2?: File;
    // fileJustE3?: File;
    // fileJustE4?: File;
    files?: Array<FileBeneficiareDTO>;
    idAdherent?: number;
    idDevis?: number;

    constructor() {
        this.files = new Array<FileBeneficiareDTO>();
    }
}
