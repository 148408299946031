import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';
import { OavUrlConstants } from '../../shared/data/OavURLConstants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordTokenService {

  publicUri = AppConfig.publicEndpoint + OavUrlConstants.RESET_CREDENTIALS_TOKEN_ENDPOINT;
  headers = new HttpHeaders();

  constructor(private http: HttpClient) { }

  checkTokenValidity(token: string): Observable<boolean> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    const options = { headers: headers, params: {reset_password_token: token}};
    // @ts-ignore
    return this.http.get(this.publicUri + OavUrlConstants.CHECK_TOKEN_VALIDITY, options);
  }
}
