export class Adherent {
    id?: number;
    nom?: string;
    prenom?: string;
    email?: string;
    adresse?: string;
    codePostal?: string;
    ville?: string;
    tel?: string;
    nomNaissance?: string;
    civilite?: boolean;

    constructor();
    constructor(nom?: string, prenom?: string, email?: string) {
        this.nom = nom;
        this.prenom = prenom;
        this.email = email;
    }
}
