export class PaymentRequest {
    signature?: string;
    vads_action_mode?: string;
    vads_amount?: string;
    vads_ctx_mode?: string;
    vads_currency?: string;
    vads_page_action?: string;
    vads_payment_config?: string;
    vads_site_id?: string;
    vads_trans_date?: string;
    vads_trans_id?: string;
    vads_version?: string;
    idDevis?: string;
    vads_url_return?: string;

    constructor() {}
}
