import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'src/app/app.config';
import { OavUrlConstants } from 'src/app/shared/data/OavURLConstants';
import { SouscripteurModelDTO } from '../models/souscripteurModelDTO';

@Injectable({
  providedIn: 'root'
})
export class SouscripteurService {

  publicUri = AppConfig.publicEndpoint + OavUrlConstants.SOUSRIPTEUR_ENDPOINT;
  securedUri = AppConfig.securedEndpoint + OavUrlConstants.SOUSRIPTEUR_ENDPOINT;

  constructor(private http: HttpClient) { }


  updateSouscripteur(souscripteur: SouscripteurModelDTO) {
    return this.http.post(this.securedUri + OavUrlConstants.UPDATE, souscripteur);
  }

  getByUser(id) {
    return this.http.get(this.securedUri + OavUrlConstants.BY_USER + '/' + id);
  }

}
