import { FinMoisDTO } from './finMoisDTO';
import { RisqueLevelDTO } from './risqueLevelDTO';

export class FormuleModelDTO {
    id?: number;
    formuleLabel?: string;
    enteteFormuleLabel?: string;
    formuleTarif?: string;
    formulePrice?: number;
    ordreRecommandation?: string;
    description?: string;
    garantieSrc?: string;
    pdfGarantie?: Uint8Array;
    dateFinAdhesionString?: string;
    dateFinAdhesion?: Date;
    nbMois?: number;
    finAdhesion: Array<FinMoisDTO>;
    risqueLevels: Array<RisqueLevelDTO>;
    showHelp: boolean;
    ipidSrc?: string;
    pdfIpid?: Uint8Array;
    rbtSrc?: string;
    pdfRbt?: Uint8Array;
    selected?: Boolean;
    fileToDownload?: string;
    constructor() {
        this.finAdhesion = new Array<FinMoisDTO>();
        this.risqueLevels = new Array<RisqueLevelDTO>();
    }
}
