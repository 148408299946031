import { Address } from './address';

export class Person {
    address?: Address;
    birthCity?: string;
    birthCountryCode?: string;
    birthDate?: string;
    birthDepartmentCode?: string;
    firstName?: string;
    gender?: string;
    idPersonPartner?: string;
    lastName?: string;
    surname?: string;
    constructor() {
        this.address = new Address();
    }
}
