export class Beneficiaire {
    id?: number;
    nomBenef?: string;
    prenomBenef?: string;
    dateNaissance?: Date;
    idRegime?: number;
    numSS?: string;
    cleSS?: string;
    typeBenef?: number;
    sexe?: boolean;
    dateClotureComptable?: Date;
    nomJeuneFille?: string;
    situation?: string;
    raisonSociale?: string;
    frontalier?: string;
    etablissement?: string;
    nationalite?: string;
    pays?: string;
    region?: string;
    mutuelle?: string;
    boursier?: string;
    echeleonBoursier?: string;
    chequeSante?: string;
    nomTypeBeneficaire?: string;
    codePostaleNaissance?: string;
    villeNaissance?: string;
    paysNaissance?: string;
    idDevis?: number;

    constructor() { }
}
