import { Injectable, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopupWrapper } from '../models/popupWrapper';
import { Observable } from 'rxjs';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';
import { SearchCountConfirmModalComponent } from '../components/search-count-confirm-modal/search-count-confirm-modal.component';
import { OavConstants } from '../data/OavConstants';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  bsModalRef: BsModalRef;

  constructor(private bsModalService: BsModalService, private cookies: CookieService) {
  }

  /**
 * Open a pop-up
 * @param component The component to be opned , should extends PopupWrapper
 * @param initialState object param
 * @param cssClass css classes
 * @param backdrop backdrop porperty
 * @param keyboard  keyboard property
 * @param ignoreBackdropClick ignoreBackdropClick property
 */
  openPopup(component: PopupWrapper | string | TemplateRef<any>,
    initialState?: object, cssClass?: string, backdrop?: boolean, keyboard?: boolean, ignoreBackdropClick?: boolean) {
    this.bsModalRef = this.bsModalService.show(component, {
      initialState: initialState,
      class: cssClass,
      backdrop: backdrop,
      keyboard: keyboard,
      ignoreBackdropClick: ignoreBackdropClick
    });
  }

  /**
 * Open a pop-up having a return value
 * @param component The component to be opned , should extends PopupWrapper
 * @param initialState object param
 * @param cssClass css classes
 * @param backdrop backdrop porperty
 * @param keyboard  keyboard property
 * @param ignoreBackdropClick ignoreBackdropClick property
 */
  openPopupSubscriber(component: PopupWrapper | string | TemplateRef<any>,
    initialState?: object, cssClass?: string, backdrop?: boolean, keyboard?: boolean, ignoreBackdropClick?: boolean): Observable<any> {
    if (this.cookies.get(OavConstants.QMS)) {
      this.bsModalRef = this.bsModalService.show(component, {
        initialState: initialState,
        class: cssClass,
        backdrop: backdrop,
        keyboard: keyboard,
        ignoreBackdropClick: ignoreBackdropClick
      });
    }
    return new Observable<any>(this.getPopupSubscriber());
  }

  private getPopupSubscriber() {
    return (observer) => {
      const subscription = this.bsModalService.onHidden.subscribe((returnedObject: any) => {
        observer.next(this.bsModalRef.content.returnedObject);
        observer.complete();
      });
    };
  }

  public closeModal() {
    this.bsModalRef.hide();
  }

  confirm(title: string, message: string, options: string[], type?: String): Observable<string> {
    const initialState = {
      title: title,
      message: message,
      options: options,
      type: 'confirm',
      answer: '',
    }
      ;
    this.bsModalRef = this.bsModalService.show(ConfirmModalComponent,
      {
        initialState: initialState,
        class: 'my-modal-confirme modal-dialog',
        backdrop: true,
        keyboard: false,
        ignoreBackdropClick: true
      }
    );
    return new Observable<string>(this.getConfirmSubscriber());
  }

  searchCountWarningConfirm(title: string, message: string, options: string[], type?: String): Observable<string> {
    const initialState = {
      title: title,
      message: message,
      options: options,
      type: 'confirm',
      answer: '',
    }
      ;
    this.bsModalRef = this.bsModalService.show(SearchCountConfirmModalComponent,
      {
        initialState: initialState,
        class: 'my-modal-confirme modal-dialog',
        backdrop: true,
        keyboard: false,
        ignoreBackdropClick: true
      }
    );
    return new Observable<string>(this.getConfirmSubscriber());
  }

  private getConfirmSubscriber() {
    return (observer) => {
      const subscription = this.bsModalService.onHidden.subscribe((reason: string) => {
        observer.next(this.bsModalRef.content.answer);
        observer.complete();
      });

      return {
        unsubscribe() {
          subscription.unsubscribe();
        }
      };
    };
  }
}
